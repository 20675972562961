//
// variables.scss
//

@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap');


@font-face {
    font-family: Gotham;
    src: url("../fonts/gotham/gotham.otf") format("opentype");
    font-display: block;
  }
  
  @font-face {
    font-family: GothamRoundedBold;
    src: url("../fonts/gotham/GothamRounded-Bold.otf") format("opentype");
    font-display: block;
  }
  
  @font-face {
    font-family: GothamRoundedMedium;
    src: url("../fonts/gotham/GothamRounded-Medium.otf") format("opentype");
    font-display: block;
  }
  
  @font-face {
    font-family: LatoLight;
    src: url("../fonts/lato/Lato-Light.ttf") format("truetype");
    font-display: block;
  }
  @font-face {
    font-family: LatoBold;
    src: url("../fonts/lato/Lato-Bold.ttf") format("truetype");
    font-display: block;
  }
  
  @font-face {
    font-family: LatoMedium;
    src: url("../fonts/lato/Lato-Medium.ttf") format("truetype");
    font-display: block;
  }

// Color
$white:                     #ffffff;

$primary:                   #2f55d4; //2f55d4
$secondary:                 #5a6d90;
$success:                   #83ac34;
$warning:                   #f17425;
$info:                      #17a2b8;
$danger:                    #e43f52;
$dark:                      #3c4858;
$black:                     #161c2d;
$muted:                     #8492a6;
$light:                     #f8f9fc;

// Gray
$gray-100:                  #f8f9fa;
$gray-200:                  #e9ecef;
$gray-300:                  #dee2e6;
$gray-400:                  #ced4da;
$gray-500:                  #adb5bd;
$gray-600:                  #6c757d; 
$gray-700:                  #495057;

// Overlay
$overlay:                   rgba($dark, 0.7);
$gradident-overlay:         rgba($primary, 0.6);
$bg-overlay-white:          rgba($white, 0.5);

//Body Background
$shadow:                    0 0 3px rgba($dark,.15);
$shadow-lg:                 0 10px 25px rgba($dark, 0.15);
$footer:                    lighten($black, 6%);

// Base font
$font-size-base:            16px;

$font-family-base:          "Gotham";
$font-family-secondary:     "LatoMedium";

//Color Picker/Switcher
$green:                     #83ac3495;
$red:                       #ff5b69;
$skyblue:                   #32c2ec;
$skobleoff:                 #0f7173;
$cyan:                      #00c9a7;

$colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "warning": $warning,
    "info": $info,
    "danger": $danger,
    "dark": $dark,
    "muted": $muted,
    "light": $light,
    "blue": $primary,
    "footer": $footer
);